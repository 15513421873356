import { Component, inject, OnInit } from '@angular/core';
import { OnlineGameplay } from '@dc-core/dc-backend/dc-interfaces';
import { LobbySettingsCollectionService } from '@dc-core/dc-firestore/collection-helpers/lobby_settings.collection.service';
import { OnlineGameJoinRequirements } from '@dc-core/dc-gamelogic/settings/online/settings-classes';
import { LocalStorageKey } from '@dc-core/dc-localstorage';
import { GameLabelService } from '@dc-core/dc-services/game-label.service';
import { OnlineGamesService } from '@dc-core/dc-services/online-games.service';
import { MenuController, ModalController, NavController } from '@ionic/angular/standalone';
import { LocalCameraService } from '@services/local-camera.service';
import { GameMode } from 'dc-core/dc-backend/dc-enums';
import { Subject } from 'rxjs';
import {
    AssignGameLabelsDialogComponent,
    AssignGameLabelsDialogPayload,
} from 'src/dialogs/assign-game-labels/assign-game-labels.dialog';

@Component({
    selector: 'app-online-setup',
    templateUrl: 'online-setup.component.html',
})
export class OnlineSetupComponent implements OnInit {
    private navController: NavController = inject(NavController);
    private modalController: ModalController = inject(ModalController);
    private menuController: MenuController = inject(MenuController);
    private online: OnlineGamesService = inject(OnlineGamesService);
    private localCameraService: LocalCameraService = inject(LocalCameraService);
    private lobbySettingsService: LobbySettingsCollectionService = inject(LobbySettingsCollectionService);
    private gameLabelService: GameLabelService = inject(GameLabelService);

    public gameMode: GameMode = 'match';
    public gameModes: { title: string; type: GameMode }[] = [
        {
            title: $localize`:@@MATCH:Match`,
            type: 'match',
        },
        {
            title: $localize`:@@CRICKET_TACTICS:Cricket / Tactics`,
            type: 'cricket_tactics',
        },
    ];

    joinRequirements: OnlineGameJoinRequirements;

    saveGameSubject: Subject<void> = new Subject<void>();
    saveRequirementsSubject: Subject<void> = new Subject<void>();

    public ngOnInit(): void {
        this.menuController.enable(true, 'mainmenu');
        this.menuController.enable(false, 'ingamemenu');

        if (this.online.onlineSavedGameplay) {
            this.gameMode = this.online.onlineSavedGameplay.type;
        }

        this.localCameraService.checkPublishingVideo();
    }

    public saveGameplay(): void {
        this.saveRequirementsSubject.next();
    }

    public assignGameLabels(): void {
        this.modalController
            .create({
                component: AssignGameLabelsDialogComponent,
                componentProps: {
                    assignedGameLabels: this.gameLabelService.selectedLabels,
                } as AssignGameLabelsDialogPayload,
                cssClass: 'auto-height',
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();
                elem.onWillDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.gameLabelService.setSelectedLabels(dialogRes.data);
                    }
                });
            });
    }

    public saveJoinRequirements(joinRequirements: OnlineGameJoinRequirements): void {
        this.joinRequirements = joinRequirements;
        this.saveGameSubject.next();
    }

    public goBack(onlineSavedGameplay?: OnlineGameplay): void {
        if (onlineSavedGameplay) {
            this.online.setJoinRequirements(this.joinRequirements, onlineSavedGameplay);
            this.setOnlineSavedGameplay(onlineSavedGameplay);

            if (this.lobbySettingsService.currentGameInLobby()) {
                this.online.updateLobbyGame();
            } else if (this.online.showGameInLobby || this.online.addGameAfterCreate) {
                this.online.setLobbyGame();
            }

            if (this.online.redirectToLobbyAfterAdding) {
                this.online.redirectToLobbyAfterAdding = false;
                this.navController.navigateBack('online-games/global-lobby');
            } else {
                this.navController.back();
            }
        } else {
            this.navController.back();
        }
    }

    private setOnlineSavedGameplay(onlineSavedGameplay?: OnlineGameplay): void {
        if (onlineSavedGameplay) {
            this.online.onlineSavedGameplay = onlineSavedGameplay;
            localStorage.setItem(LocalStorageKey.onlineSavedGameplay, JSON.stringify(onlineSavedGameplay));
        } else {
            localStorage.removeItem(LocalStorageKey.onlineSavedGameplay);
        }
    }
}
