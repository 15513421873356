<app-page-header mainNavigation="custom" defaultBack="online-games" color="white">
    <div left class="flex items-center cursor-pointer -ml-2" (click)="goBack()">
        <app-icon icon="keyboard_arrow_left" size="large"></app-icon>
        <div class="text-center text-sm font-bold !text-white" [translate]="'CANCEL'" i18n="@@CANCEL">Cancel</div>
    </div>
    <div title [translate]="'ONLINE_SETUP'" i18n="@@ONLINE_SETUP">Online setup</div>
    <app-control-camera-icon [refreshGame]="true" right></app-control-camera-icon>
</app-page-header>

<div class="relative flex flex-1 flex-col overflow-hidden bg-white-pattern p-4 text-black pb-safe-offset-4">
    <div class="flex-1 overflow-y-auto" [appWebContainer]>
        <div class="mb-4 flex h-12 flex-none rounded-md bg-panel">
            <div
                class="tab-toggle"
                [class.inner-border-b-2-orange]="gameMode === modeFromList.type"
                [class.opacity-60]="gameMode !== modeFromList.type"
                [class.rounded-l]="index === 0"
                [class.rounded-r]="index === gameModes.length - 1"
                (click)="gameMode = modeFromList.type"
                *ngFor="let modeFromList of gameModes; let index = index">
                <div class="font-bebas-neue text-xl text-black">{{ modeFromList.title }}</div>
            </div>
        </div>

        @if (gameMode === 'match') {
            <app-match-online-setup
                class="block"
                [saveEvents]="saveGameSubject.asObservable()"
                (saveEvent)="goBack($event)"></app-match-online-setup>
        }
        @if (gameMode === 'cricket_tactics') {
            <app-cricket-tactics-online-setup
                class="block"
                [saveEvents]="saveGameSubject.asObservable()"
                (saveEvent)="goBack($event)"></app-cricket-tactics-online-setup>
        }

        <app-games-online-edit-join-requirements
            class="block mt-2"
            [saveEvents]="saveRequirementsSubject.asObservable()"
            (saveEvent)="saveJoinRequirements($event)"></app-games-online-edit-join-requirements>
    </div>

    <div class="flex-none" [appWebContainer]>
        <app-primary-button
            class="mt-4 block"
            size="normal"
            addedClasses="w-full"
            [translate]="'SAVE_GAMEPLAY'"
            i18n="@@SAVE_GAMEPLAY"
            (click)="saveGameplay()">
            Save gameplay
        </app-primary-button>
    </div>
</div>
