/* eslint-disable max-len */
import { CommonModule } from '@angular/common';
import { Component, computed, DestroyRef, EventEmitter, inject, Input, OnInit, Output, Signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Browser } from '@capacitor/browser';
import { SmartDeviceApiService } from '@dc-api/smart-device.api.service';
import { OnlineGameJoinRequirements } from '@dc-core/dc-gamelogic/settings/online/settings-classes';
import { AlertService } from '@dc-core/dc-services/alert.service';
import { JanusRoom } from '@dc-core/dc-services/camera/camera.models';
import { ConnectionStatus, OmniCommunicationService } from '@dc-core/dc-services/omni/omni-communication.service';
import { OmniIngameService } from '@dc-core/dc-services/omni/omni-ingame.service';
import { OnlineGamesService } from '@dc-core/dc-services/online-games.service';
import { ModalController } from '@ionic/angular/standalone';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import { SmartDeviceService } from '@services/smart-device.service';
import { Observable } from 'rxjs';
import { GA_EVENTCATEGORIES } from 'src/app/app.globals';
import { UltimateFeatureID } from 'src/app/core/app-features/services/app-features.service';
import { JanusStateService } from 'src/app/services/janus/janus-state.service';
import { UpgradeService } from 'src/app/services/upgrade.service';
import { ToggleSwitchComponent } from 'src/app/toggle-switch/toggle-switch.component';
import { ActivateCameraDialogComponent } from 'src/dialogs/activate-camera/activate-camera.dialog';
import { OmniActivationDialogComponent } from 'src/dialogs/omni-activation-dialog/omni-activation.dialog';
import { UltimateDialogPayload } from 'src/dialogs/ultimate-subscription/ultimate-subscription';
import { environment } from 'src/environments/environment';
import { SmartDeviceStatus } from 'src/pages/online/online-games/online-games.component';

import { IconComponent } from '../../../../shared/components/icon/icon.component';

@Component({
    selector: 'app-games-online-edit-join-requirements',
    templateUrl: 'games-online-edit-join-requirements.component.html',
    standalone: true,
    imports: [CommonModule, TranslateModule, ToggleSwitchComponent, IconComponent],
})
export class GamesOnlineEditJoinRequirementsComponent implements OnInit {
    @Input() saveEvents: Observable<void>;
    @Output() saveEvent = new EventEmitter<OnlineGameJoinRequirements>();

    public online: OnlineGamesService = inject(OnlineGamesService);
    public authService: AuthService = inject(AuthService);
    public omniIngameService: OmniIngameService = inject(OmniIngameService);

    private janusStateService: JanusStateService = inject(JanusStateService);
    private alertService: AlertService = inject(AlertService);
    private modalController: ModalController = inject(ModalController);
    private smartDeviceService: SmartDeviceService = inject(SmartDeviceService);
    private omniCommunicationService: OmniCommunicationService = inject(OmniCommunicationService);
    private smartDeviceApiService: SmartDeviceApiService = inject(SmartDeviceApiService);
    private upgradeService: UpgradeService = inject(UpgradeService);

    public requirements: OnlineGameJoinRequirements = null;

    readonly SmartDeviceStatus = SmartDeviceStatus;

    public omniStatus: Signal<SmartDeviceStatus> = computed(() => this.smartDeviceService.omniStatus());

    protected destroyRef: DestroyRef = inject(DestroyRef);

    public ngOnInit(): void {
        this.requirements = this.online.getJoinRequirements(this.authService.user, this.online.onlineSavedGameplay);

        this.smartDeviceApiService.hasOmni$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((hasOmni) => {
            if (hasOmni) {
                if (!this.omniIngameService.omniConnected()) {
                    this.smartDeviceService.omniStatus.set(SmartDeviceStatus.OWNED);
                } else {
                    this.smartDeviceService.omniStatus.set(SmartDeviceStatus.ACTIVATED);
                }
            }
        });

        this.saveEvents.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            this.saveEvent.emit(this.requirements);
        });
    }

    public async toggleUltimateOnly(): Promise<void> {
        if (!this.authService.user.is_ultimate) {
            const dialogComponent = await this.upgradeService.getUpgradeDialog(GA_EVENTCATEGORIES.STATISTICS);
            this.modalController
                .create({
                    component: dialogComponent,
                    componentProps: {
                        highlightedFeatureID: UltimateFeatureID.PLAY_ONLINE,
                    } as UltimateDialogPayload,
                    cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
                })
                .then((elem) => {
                    elem.present();
                });
            return;
        }

        this.requirements.ultimateOnly = !this.requirements.ultimateOnly;
    }

    public toggleCameraOnly(): void {
        if (!this.requirements.cameraOnly && !this.janusStateService.videoRoom()?.roomID) {
            this.activateCamera();
        } else if (this.requirements.cameraOnly || this.janusStateService.videoRoom()?.roomID) {
            this.requirements.cameraOnly = !this.requirements.cameraOnly;
            this.requirements.cameraOrOmniScoringOnly = false;
        }
    }

    public toggleOmniScoringOnly(): void {
        if (!this.smartDeviceApiService.omniScorings().length) {
            this.alertService.createAlert({
                title: $localize`:@@NEED_TO_HAVE_AN_OMNI_SCORING:You need to have an Omni Scoring`,
                icon: 'error',
                timer: null,
                showCloseButton: true,
                confirmButtonText: $localize`:@@VISIT_TARGET_DARTS_STORE:Visit Target Darts store`,
                onConfirm: () => {
                    Browser.open({ url: 'https://www.target-darts.co.uk/omni' });
                },
            });
            return;
        }

        if (!this.requirements.omniScoringOnly && this.omniStatus() === SmartDeviceStatus.OWNED) {
            this.activateOmni();
        } else if (this.requirements.omniScoringOnly || this.omniStatus() === SmartDeviceStatus.ACTIVATED) {
            this.requirements.omniScoringOnly = !this.requirements.omniScoringOnly;
            this.requirements.cameraOrOmniScoringOnly = false;
        }
    }

    public toggleCameraOrOmniScoringOnly(): void {
        if (!this.requirements.cameraOrOmniScoringOnly && !this.janusStateService.videoRoom()?.roomID) {
            this.activateCamera();
        } else if (this.requirements.cameraOrOmniScoringOnly || this.janusStateService.videoRoom()?.roomID) {
            this.requirements.cameraOnly = false;
            this.requirements.omniScoringOnly = false;
            this.requirements.cameraOrOmniScoringOnly = !this.requirements.cameraOrOmniScoringOnly;
        }
    }

    private activateCamera(): void {
        this.modalController
            .create({
                component: ActivateCameraDialogComponent,
                backdropDismiss: false,
                showBackdrop: true,
                cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    const camera: JanusRoom = dialogRes.data;
                    if (camera) {
                        this.alertService.createAlert({
                            icon: 'success',
                            title: $localize`:@@CAMERA_IS_ACTIVATED:Your camera is activated for online games!`,
                        });

                        this.toggleCameraOnly();
                    }
                });
            });
    }

    private activateOmni(): void {
        const networks = this.smartDeviceService.getNetworksByType(['omni_scoring']);
        if (!networks.totalDevices) {
            return;
        }

        if (!this.omniIngameService.omniConnected()) {
            if (networks.totalDevices === 1) {
                const connectionSub = this.omniCommunicationService.connectionStatus$
                    .pipe(takeUntilDestroyed(this.destroyRef))
                    .subscribe((connectionStatus) => {
                        if (connectionStatus === ConnectionStatus.CONNECTED) {
                            this.alertService.clearAlerts();
                            connectionSub.unsubscribe();

                            this.checkOmniConnectionStatus(connectionStatus);
                        } else if (connectionStatus === ConnectionStatus.DISCONNECTED) {
                            this.alertService.clearAlerts();
                            connectionSub.unsubscribe();

                            this.checkOmniConnectionStatus(connectionStatus);
                        }
                    });

                this.omniCommunicationService.loadAndConnect(networks.networks[0].devices[0]);
                return;
            }

            if (!environment.isWeb || (environment.isWeb && this.smartDeviceApiService.webSupportedOmnis().length)) {
                this.modalController
                    .create({
                        component: OmniActivationDialogComponent,
                        componentProps: {
                            startGameAfterConnection: false, //Dont start watching darts, as you're still in the lobby
                        },
                        cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
                        backdropDismiss: true,
                    })
                    .then((elem) => {
                        elem.present();
                        elem.onDidDismiss().then((dialogRes) => {
                            const connectionStatus = dialogRes.data as ConnectionStatus;
                            if (connectionStatus) {
                                this.checkOmniConnectionStatus(connectionStatus);
                            }
                        });
                    });
            }
        }
    }

    private checkOmniConnectionStatus(connectionStatus: ConnectionStatus): void {
        if (connectionStatus === ConnectionStatus.CONNECTED) {
            this.smartDeviceService.omniStatus.set(SmartDeviceStatus.ACTIVATED);
            this.toggleOmniScoringOnly();
        } else {
            this.omniIngameService.disconnect(true);

            this.alertService.createAlert({
                title: $localize`:@@OMNI_COULD_NOT_CONNECT:Could not connect to your Omni Scoring`,
                icon: 'error',
                timer: 2000,
                timerProgressBar: true,
                showCloseButton: true,
            });
        }
    }
}
