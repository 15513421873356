/* eslint-disable max-len */
import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HelpModalComponent } from '@components/help-modal/help-modal.component';
import { UserApiService } from '@dc-api/user.api.service';
import { OnlineGameplay } from '@dc-core/dc-backend/dc-interfaces';
import { DartCounterOnlineMatchSettings } from '@dc-core/dc-gamelogic/settings/online/match/match-settings.default';
import { StartWith } from '@dc-core/dc-gamelogic/settings/settings.globals';
import { ModalController, NavController } from '@ionic/angular/standalone';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'dc-core/dc-services/alert.service';
import { Observable, Subscription } from 'rxjs';
import { CustomScoreDialogComponent } from 'src/dialogs/customscore/customscore.dialog';
import { GoalAmountDialogComponent } from 'src/dialogs/goalamount/goalamount.dialog';
import { SuddenDeathDialogComponent } from 'src/dialogs/suddendeath/suddendeath.dialog';
import { TimerDurationDialogComponent } from 'src/dialogs/timerduration/timerduration.dialog';
import { TutorialDialogComponent } from 'src/dialogs/tutorial/tutorial';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/services/auth.service';

import { FireStoreService } from '../../../providers/FirebaseService';

@Component({
    selector: 'app-match-online-setup',
    templateUrl: 'match.online-setup.component.html',
})
export class MatchOnlineSetupComponent extends DartCounterOnlineMatchSettings implements OnInit, OnDestroy {
    @Input() saveEvents: Observable<void>;
    @Output() saveEvent = new EventEmitter<OnlineGameplay>();
    private eventsSubscription: Subscription;

    private modalController: ModalController = inject(ModalController);

    constructor(
        public nav: NavController,
        public auth: AuthService,
        public firestore: FireStoreService,
        public route: ActivatedRoute,
        private translate: TranslateService,
        private _userApiService: UserApiService,
        private _alertService: AlertService
    ) {
        super();
    }

    ngOnInit(): void {
        this.setUser(this.auth.user);
        this.setOnlineGameMode('match');

        this.eventsSubscription = this.saveEvents.subscribe(() => this.confirm());
    }

    ngOnDestroy(): void {
        this.eventsSubscription.unsubscribe();
    }

    dismiss() {
        this.modalController.dismiss();
    }

    public confirm(): void {
        const errors = this.checkForErrors();
        if (errors.length) {
            this._alertService.createAlert({
                title: errors.join('\n'),
                icon: 'error',
                timer: 3000,
            });
        } else {
            this.saveSettings();

            this._alertService.createAlert({
                title: $localize`:@@GAMEPLAY_HAS_BEEN_CREATED:Gameplay settings have been saved`,
                icon: 'success',
                timer: 'default',
            });

            this.saveEvent.next(this.mapToOnlineGameplayObject());
        }
    }

    toggleCheckoutPercentage() {
        this.auth.user.profile.has_checkout_rate = !this.auth.user.profile.has_checkout_rate;
        this.checkoutPercentage = this.auth.user.profile.has_checkout_rate;

        this._userApiService.updateUser({
            profile: {
                has_checkout_rate: this.auth.user.profile.has_checkout_rate,
            },
        });
    }

    changeStartWith(startWith: StartWith) {
        super.changeStartWith(startWith);
        if (startWith === 'custom') {
            this.modalController
                .create({
                    component: CustomScoreDialogComponent,
                    backdropDismiss: true,
                    componentProps: {
                        customScore: this.customStartWith,
                        minScore: this.minCustomStartWith,
                        maxScore: this.maxCustomStartWith,
                    },
                    cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
                })
                .then((elem) => {
                    elem.present();
                    elem.onDidDismiss().then((dialogRes) => {
                        if (dialogRes.data) {
                            this.customStartWith = dialogRes.data;
                            const errors = this.checkForErrors();
                            if (errors.length) {
                                this._alertService.createAlert({
                                    title: errors.join('\n'),
                                    icon: 'error',
                                    timer: 3000,
                                });
                            }
                        }
                    });
                });
        }
    }

    toggleCheckoutRate() {
        if (!this.checkoutPercentage) {
            if (this.finishMode === 'straight_out') {
                this._alertService.createAlert({
                    icon: 'error',
                    title: $localize`:@@CHECKOUT_RATE_NOT_WITH_SINGLE_OUT:Checkout rate not supported with straight-out mode`,
                });
                return false;
            } else if (this.finishMode === 'master_out') {
                this._alertService.createAlert({
                    icon: 'error',
                    title: $localize`:@@CHECKOUT_RATE_NOT_WITH_MASTER_OUT:Checkout rate not supported with master-out mode`,
                });
                return false;
            }
        }
        this.checkoutPercentage = !this.checkoutPercentage;
    }

    toggleFastMode() {
        this.fastScoring = !this.fastScoring;
    }

    toggleTwoLegsDiff() {
        this.twoLegsDifference = !this.twoLegsDifference;

        if (this.twoLegsDifference) {
            let dialogRef;
            this.modalController
                .create({
                    component: SuddenDeathDialogComponent,
                    backdropDismiss: true,
                    componentProps: {
                        suddenDeath: JSON.parse(JSON.stringify(this.suddenDeath)),
                    },
                    cssClass: 'auto-height',
                })
                .then((elem) => {
                    elem.present();
                    dialogRef = elem;
                    dialogRef.onDidDismiss().then((dialogRes) => {
                        if (dialogRes.data) {
                            this.suddenDeath = dialogRes.data;
                        }
                    });
                });
        } else {
            this.suddenDeath = false;
        }
    }

    showGoalAmountDialog() {
        this.modalController
            .create({
                component: GoalAmountDialogComponent,
                backdropDismiss: true,
                componentProps: {
                    goalAmount: JSON.parse(JSON.stringify(this.goalAmount)),
                },
                cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.goalAmount = dialogRes.data;
                        const errors = this.checkForErrors();
                        if (errors.length) {
                            this._alertService.createAlert({
                                title: errors.join('\n'),
                                icon: 'error',
                                timer: 3000,
                            });
                        }
                    }
                });
            });
    }

    showTimerDurationDialog() {
        let dialogRef;
        this.modalController
            .create({
                component: TimerDurationDialogComponent,
                backdropDismiss: true,
                componentProps: {
                    timerDuration: JSON.parse(JSON.stringify(this.inGameTimer)),
                },
                cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
            })
            .then((elem) => {
                elem.present();
                dialogRef = elem;

                dialogRef.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.inGameTimer = dialogRes.data;
                        const errors = this.checkForErrors();
                        if (errors.length) {
                            this._alertService.createAlert({
                                title: errors.join('\n'),
                                icon: 'error',
                                timer: 3000,
                            });
                        }
                    }
                });
            });
    }

    showFastScoringInfo(): void {
        this.modalController
            .create({
                component: TutorialDialogComponent,
                backdropDismiss: true,
                componentProps: {
                    slides: [
                        {
                            title: this.translate.instant('SEND_SCORES_IMMEDIATELY'),
                            description: this.translate.instant('FAST_OR_SLOW'),
                        },
                    ],
                },
                cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
            })
            .then((elem) => {
                elem.present();
            });
    }

    showHelpModal(title, contents) {
        this.modalController
            .create({
                component: HelpModalComponent,
                componentProps: {
                    title: this.translate.instant(title),
                    contents: this.translate.instant(contents),
                },
                cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
                backdropDismiss: true,
            })
            .then((elem) => {
                elem.present();
            });
    }
}
